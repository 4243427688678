import React from "react"
import "./style.css"
import Bullet from "../../../static/svg/bullet.svg"
import QuoteLogo from "../../images/aray_quote_logo.png"
import Frame from "../../components/Frame"
import VideoContainer from "../../components/VideoContainer"
//REFACTOR: avoid relative path but set default path using @
import videoSrc from "../../../static/video_homepage.mp4"

const Features = () => {
    const arayFeatures = [
        <p>
            <span className="bold">Connect</span> with other female golfers
            globally
        </p>,
        <p>
            <span className="bold">Get exclusive invitations</span> to events,
            meet-ups & tournaments
        </p>,
        <p className="bold">Join golf trips</p>,
        <p>
            <span className="bold">Receive special deals</span> on greenfees &
            tournaments
        </p>,
        <p>
            <span className="bold">Explore unique offers</span> from fashion,
            equipment & lifestyle brands
        </p>,
        <p>
            <span className="bold">Improve your game</span> with top instructors
            in ARAY Academy
        </p>,
        <p>
            <span className="bold">Explore & review</span> golf courses
        </p>
    ]
    return (
        <>
            <div className="features-container center">
                <div className="features-container__points">
                    <h3>As a member of ARAY Society you will be able to:</h3>
                    {arayFeatures.map(text => (
                        <div className="features-row" key={text}>
                            <img
                                src={Bullet}
                                alt="bullet-icon"
                                className="bullet-icon"
                            />
                            {text}
                        </div>
                    ))}
                    {/* <div className="features-row">
                        <img
                            src={Bullet}
                            alt="bullet-icon"
                            className="bullet-icon"
                        />
                        <div className="features-row-with-sub-list">
                            <p className="bold">
                                Get access to amazing partner offers and
                                discounts on e.g.:
                            </p>
                            <div className="features__sub-list">
                                <span>
                                    - All golf courses connected to ARAY
                                </span>Mindfully
                                <span>
                                    - All indoor golf centers connected to ARAY
                                </span>
                                <span>- The latest golf fashion</span>
                                <span>- Equipment</span>
                                <span className="features__sub-list__more">
                                    ...and we are just getting started!
                                </span>
                            </div>
                        </div>
                    </div> */}
                </div>
                <VideoContainer src={videoSrc} />
            </div>
            <Frame frame={2}>
                <div
                    className="features-container"
                    style={{ justifyContent: "space-between" }}
                >
                    <div className="quote">
                        “ARAY Society was born out of the desire to empower,
                        inspire and create an inclusiveness for female golfers
                        on all levels of play.”
                    </div>
                    <img src={QuoteLogo} alt="quote_logo" />
                </div>
            </Frame>
        </>
    )
}

export default Features
