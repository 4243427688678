import React, { useState } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import AppRow from "../components/AppRow"
import PartnerForm from "../components/FormPartner"

// sections
import PartnerSection from "../section/Partner"
import Signup from "../section/Signup"
import FeaturesAndBenefits from "../section/FeaturesAndBenefits"
import "../styles/home.css"

const IndexPage = () => {
    const [isPartnerFormVisible, setIsPartnerFormVisible] = useState(false)

    return (
        <Layout>
            <Seo title="Home" />
            <Signup />

            <FeaturesAndBenefits />
            <PartnerSection />
            {/* <AppRow
                title="Connect & play"
                description="Regardless if you’ve never played before, you’re a member at several clubs or non at all, age or interest, you can find and connect with others to play and enjoy your time with."
                picNumArr={[1, 2]}
            />
            <AppRow
                title="Golf clubs"
                description="When in a new city, moving, new at school or wanting to increase you network; post tee-times or reach out to someone you got matched with and start growing your network of female golf friends."
                picNumArr={[3, 4]}
                isReversed
            />
            <AppRow
                title="Brands"
                description="Articles, videos, podcasts, webinars or shared by you. There are many different ways you can learn about the different aspects of golf. Want to know more about your game, networking at work or on the course, work in the industry or how to be more healthy, we got you covered."
                picNumArr={[5, 6]}
            /> */}
        </Layout>
    )
}

export default IndexPage
